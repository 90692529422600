body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import '@mathison-inc/components/dist/main.css';

/* Remove this after App portal adapt the new design system */
@font-face {
  font-family: 'D-DIN';
  src: url('./assets/font/DINPro-Medium.woff2') format('woff2'),
    url('./assets/font/DINPro-Medium.woff') format('woff');
  font-weight: 500;
}
